import { ArticleTeaseProps } from "design-system/components/primitives/article-tease/article-tease";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { ArticlePageEntry } from "frontend/contentful/schema/pages";
import { createMediaAssetProps } from "frontend/contentful/components/primitives/media-asset.props";
import { documentToString } from "frontend/contentful/lib/document-to-string";
import { createBylineProps } from "frontend/contentful/components/primitives/byline.props";
import { useContentful } from "frontend/hooks/use-contentful";

export const createArticleTeaseProps = createContentfulProps<
  ArticlePageEntry,
  ArticleTeaseProps
>(({ entry, createEditAttributes }) => {
  const { getEntry, getEntryUrl } = useContentful();

  const props: ArticleTeaseProps = {
    type: "Article",
    title: entry.fields?.title,
    link: getEntryUrl(entry),
    byline: createBylineProps(entry),
    tease: documentToString(
      entry.fields?.description ?? entry.fields?.shortDescription,
    ),

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
    },
  };

  const mediaAsset = createMediaAssetProps(
    entry.fields.teaseImage || entry.fields.leadImage,
  );
  // force 4:3 aspect ratio
  if (mediaAsset?.image) {
    mediaAsset.image.width = "600px";
    mediaAsset.image.height = "450px";
  }

  props.image = mediaAsset?.image;
  props.video = mediaAsset?.video;
  props.kaltura = mediaAsset?.kaltura;

  const overlineEntry = getEntry(entry.fields.overline);

  if (overlineEntry?.fields.text && overlineEntry.fields.url) {
    props.overline = {
      label: overlineEntry.fields.text,
      link: getEntryUrl(overlineEntry),
    };
  }

  return props;
});
